// Polyfill to support document.currentScript in IE
// Inspired by: https://github.com/amiller-gh/currentScript-polyfill
;((document) => {
  const currentScript = 'currentScript'
  const scripts = document.getElementsByTagName('script') // Live NodeList collection

  // If browser needs currentScript polyfill, add get currentScript() to the document object
  if (!(currentScript in document)) {
    Object.defineProperty(document, currentScript, {
      get: () => {
        // IE 6-10 supports script readyState
        // IE 10+ support stack trace
        try {
          throw new Error()
        } catch (err) {
          // Find the second match for the "at" string to get file src url from stack.
          // Specifically works with the format of stack traces in IE.
          const res = (/.*at [^\(]*\((.*):.+:.+\)$/gi.exec(err.stack) || [false])[1]

          // For all scripts on the page, if src matches or if ready state is interactive, return the script tag
          for (const i in scripts) {
            if (scripts[i].src === res) {
              return scripts[i]
            }
          }

          // If no match, return null
          return null
        }
      },
    })
  }
})(document)
