import { parse } from 'query-string'

import { QueryParamDefaults } from './constants'
import { QueryParamKeys, QueryParamParsers } from '@evenfinancial/even-ts-static'
export interface RawParams {
  [key: string]: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Params = { [K in QueryParamKeys]?: any }

export const TAG_PREFIX = 'tag.'

/**
 * @deprecated use embed/utils/query-params
 */
export class QueryParams {
  public getAllRaw(queryString?: string): RawParams {
    return parse(queryString ?? window?.location?.search ?? '')
  }
  public get = (key: QueryParamKeys, queryString?: string) => this.getAll(queryString)[key]

  public getAll = (queryString?: string): Params => {
    const params = { ...QueryParamDefaults }
    Object.entries(this.getAllRaw(queryString)).forEach(([key, value]) => {
      const parser = QueryParamParsers[key] ? QueryParamParsers[key] : undefined
      params[key] = parser !== undefined ? parser(value) : value
    })

    return Object.keys(params).reduce((acc, key) => {
      if (params[key] !== null && params[key] !== undefined) acc[key] = params[key]
      return acc
    }, {})
  }
}

const instance = new QueryParams()

export { instance as default }
