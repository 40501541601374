import { parse, stringify } from 'query-string'

import * as iframeResizer from 'iframe-resizer'
import { throttle } from 'throttle-debounce'
import './polyfills'
import { smoothScrollTo } from './smoothScrollTo'

import {
  EVEN_REQUEST_REDIRECT_EVENT,
  EVEN_VIEWPORT_UPDATE_EVENT,
  TargetTypes,
  ViewportUpdateMessageBody,
} from './constants'

import QueryParams from './query_params'
import {
  captureCantSendHelperMessage,
  captureErrorOpeningWindowFromHelperEvent,
  captureNoChildElementWarning,
  captureOriginParseError,
} from './errors'
import {
  EVEN_AUTO_SCROLL_URL_KEY,
  EVEN_HELPER_EVENT,
  EVEN_IFRAME_INIT_EVENT,
  EVEN_SCROLL_EVENT,
  EVEN_URL_APPEND_EVENT,
  EVEN_URL_PARAM_REMOVE_EVENT,
  QueryParamKeys,
} from '@evenfinancial/even-ts-static'
interface ParentContext {
  url: string
  referrer?: string
}

const PROTOCOL_WHITELIST = new Set(['http:', 'https:'])
const urlMatchesApprovedProtocols = (url: string | URL): boolean => {
  const urlInstance = typeof url === 'string' ? new URL(url) : url
  const protocol = urlInstance.protocol?.toLowerCase()

  return PROTOCOL_WHITELIST.has(protocol)
}

const ORIGIN_ROOT_DOMAIN_WHITELIST = new Set([
  'cnf.land',
  'cnf.rocks',
  'engine.tech',
  'evenfinancial.com',
  'fiona.com',
  'hifiona.com',
  'leaplife.com',
  'moneylion.com',
])
const ORIGIN_ROOT_DOMAIN_WITH_ANY_SUBDOMAIN_REGEX = new RegExp(
  `.*\\.(${Array.from(ORIGIN_ROOT_DOMAIN_WHITELIST)
    .map((domainStr) => `(${domainStr.split('.').join('\\.')})`)
    .join('|')})$`,
  'i',
)
const urlMatchesApprovedDomains = (url: string | URL): boolean => {
  const urlInstance = typeof url === 'string' ? new URL(url) : url

  if (!urlInstance.hostname) {
    return false
  }

  const hostname = urlInstance.hostname.toLowerCase()

  return !!(
    hostname &&
    (ORIGIN_ROOT_DOMAIN_WHITELIST.has(hostname) || ORIGIN_ROOT_DOMAIN_WITH_ANY_SUBDOMAIN_REGEX.test(hostname))
  )
}

const isDev = (() => {
  const currentScriptSrc = (document?.currentScript as HTMLScriptElement)?.src
  if (currentScriptSrc) {
    const currentScriptUrlInstance = new URL(currentScriptSrc, window.location.href)
    const hostname = currentScriptUrlInstance.hostname.toLowerCase()

    if (hostname === 'localhost' || hostname === '127.0.0.1') {
      return true
    }

    if (urlMatchesApprovedDomains(currentScriptUrlInstance)) {
      return hostname.split('.').includes('dev')
    }
  }

  return false
})()

const urlMatchesApprovedOrigins = (url: string | URL): boolean => {
  try {
    // If we're in dev, allow all message to be sent so we can use localhost or any valid domain
    // We need to use a valid domain like dev-localhost.com to have Google Optimize be able to
    // set cookies during local development.
    if (isDev) {
      return true
    }

    const urlInstance = typeof url === 'string' ? new URL(url) : url

    return urlMatchesApprovedDomains(urlInstance) && urlMatchesApprovedProtocols(urlInstance)
  } catch (err) {
    captureOriginParseError(err, url?.toString?.() ?? 'null')
    return false
  }
}

const VIEWPORT_THROTTLE = 800
const SCROLL_TIME = 800

// Helper script will be placed alongside any iframes
// its primary purpose is to provide access to the surrounding
// context (parent window, referrer information), and aid with iframe responsivity
export class Helper {
  private readonly window: Window
  private childElement: HTMLIFrameElement
  private isScrolling: boolean
  private scrollTimeout

  constructor(window: Window) {
    this.window = window
    this.window.addEventListener('message', this.messageListener)
    this.window.addEventListener('scroll', throttle(VIEWPORT_THROTTLE, this.viewportUpdate))
    this.window.addEventListener('resize', throttle(VIEWPORT_THROTTLE, this.viewportUpdate))
  }

  scroll(scrollY) {
    this.isScrolling = true
    this.window.clearTimeout(this.scrollTimeout)
    this.scrollTimeout = this.window.setTimeout(() => (this.isScrolling = false), SCROLL_TIME)

    // Firefox and Chrome store scrollTop on the documentElement
    // Safari stores scrollTop on the document body
    // We check if scrollTop is falsy (0) to switch to body on Safari
    smoothScrollTo(
      this.window.document.documentElement.scrollTop ? this.window.document.documentElement : this.window.document.body,
      scrollY,
      SCROLL_TIME,
    )
  }

  postParentDataToChild() {
    const body: ParentContext = {
      url: this.window.location.href,
    }

    if (this.window.document.referrer.length > 0) {
      body.referrer = this.window.document.referrer
    }

    this.sendMessageToChild(body, EVEN_HELPER_EVENT)
  }

  sendMessageToChild(body: object, type: string) {
    const childWindow = this.childElement.contentWindow

    if (!childWindow) {
      captureNoChildElementWarning()
      return
    }

    const childElementUrl = new URL(this.childElement.src, this.window.location.href)

    if (urlMatchesApprovedOrigins(childElementUrl)) {
      childWindow.postMessage(
        {
          body: JSON.stringify(body),
          type,
        },
        childElementUrl.origin,
      )
    } else {
      captureCantSendHelperMessage(this.childElement.src)
    }
  }

  private appendToUrl(param) {
    const { search } = this.window.location
    let parsedSearch = parse(search)
    // if not a clientTag, replace value
    if (param.key.indexOf('tag.') === -1) {
      parsedSearch = { ...parsedSearch, [param.key]: param.value }
    }
    this.setNewUrl(parsedSearch)
  }

  private removeFromUrl(param) {
    const { search } = this.window.location
    const parsedSearch = parse(search)
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete parsedSearch[param.key]
    this.setNewUrl(parsedSearch)
  }

  private setNewUrl(parsedSearch) {
    const { origin, pathname } = this.window.location
    const newUrl = `${origin}${pathname}?${stringify(parsedSearch)}`
    this.window.history.pushState(this.window.history.state, '', newUrl)
  }

  private handleInitEvent(msg) {
    const iframes: HTMLIFrameElement[] = [].slice.call(this.window.document.getElementsByTagName('iframe'))
    iframes.forEach((iframe) => {
      if (iframe.contentWindow === msg.source) {
        this.childElement = iframe
      }
    })

    if (this.childElement) {
      const childElementSrc = this.childElement.src
      const queryString = childElementSrc.substring(childElementSrc.indexOf('?'))
      const queryParams = QueryParams.getAll(queryString)
      const resizeIframeParam = queryParams[QueryParamKeys.ResizeIframe] ?? true

      const heightParam = queryParams[QueryParamKeys.IframeHeight]
      const widthParam = queryParams[QueryParamKeys.IframeWidth]

      if (resizeIframeParam) {
        iframeResizer.iframeResizer(
          {
            sizeHeight: heightParam === undefined,
            sizeWidth: widthParam !== undefined,
          },
          this.childElement,
        )
      }
      if (heightParam) {
        this.childElement.style.height = `${heightParam}`
      }
      if (widthParam) {
        this.childElement.style.width = `${widthParam}`
      }
    }

    if (this.childElement) {
      this.postParentDataToChild()
    } else {
      captureNoChildElementWarning()
    }
  }

  private handleScrollEvent(msg, body) {
    if (!this.childElement) {
      captureNoChildElementWarning()
      return
    }

    if (QueryParams.get(EVEN_AUTO_SCROLL_URL_KEY as QueryParamKeys, this.childElement.src) !== 'false') {
      const iframeTop: number = this.childElement.getBoundingClientRect().top
      const offset = this.window.pageYOffset || this.window.document.documentElement.scrollTop
      const additional: number = body.additionalOffset
      // gives 15% of viewport height padding above top of iframe to account for floating nav bars etc.
      const wiggleRoom = 0.15
      const viewportHeight = Math.max(this.window.document.documentElement.clientHeight, this.window.innerHeight || 0)

      const scrollValue = offset + iframeTop + additional - wiggleRoom * viewportHeight
      this.scroll(scrollValue)
    }
  }

  private handleRequestRedirectEvent(body) {
    const urlInstance = new URL(body.url)

    if (!urlMatchesApprovedProtocols(urlInstance)) {
      console.error('handleRequestRedirectEvent: cannot redirect to url with unapproved protocol', body)
      captureErrorOpeningWindowFromHelperEvent(body.url)
      return
    }

    const openedWindow = this.window.open(body.url, body.target)
    if (body.target === TargetTypes.CURRENT_WINDOW && openedWindow?.focus() === undefined) {
      captureErrorOpeningWindowFromHelperEvent(body.url)
      this.sendMessageToChild(body, EVEN_REQUEST_REDIRECT_EVENT)
    }
  }

  private handleURLAppendEvent(body) {
    if (body.key !== undefined && body.value !== undefined) {
      this.appendToUrl(body)
    }
  }

  private handleURLRemoveEvent(body) {
    if (body.key !== undefined) {
      this.removeFromUrl(body)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly messageListener = (msg: MessageEvent<any>) => {
    // Only accept messages from approved origins
    if (urlMatchesApprovedOrigins(msg.origin)) {
      let body

      if (msg.data.body) {
        try {
          body = JSON.parse(msg.data.body)
        } catch (e) {
          return
        }
      }

      switch (msg.data.type) {
        case EVEN_IFRAME_INIT_EVENT:
          this.handleInitEvent(msg)
          break
        case EVEN_SCROLL_EVENT:
          this.handleScrollEvent(msg, body)
          break
        case EVEN_URL_APPEND_EVENT:
          this.handleURLAppendEvent(body)
          break
        case EVEN_URL_PARAM_REMOVE_EVENT:
          this.handleURLRemoveEvent(body)
          break
        case EVEN_REQUEST_REDIRECT_EVENT:
          this.handleRequestRedirectEvent(body)
          break
        default:
          break
      }
    }
  }

  // Send viewport dimensions, scroll, and child iframe rect to child
  private readonly viewportUpdate = () => {
    if (!this.childElement) {
      return
    }

    const { bottom, height, left, right, top, width } = this.childElement.getBoundingClientRect()
    const viewportUpdateBody: ViewportUpdateMessageBody = {
      automatedScrolling: this.isScrolling,
      childRect: {
        bottom,
        height,
        left,
        right,
        top,
        width,
      },
      parentRect: {
        height: this.window.innerHeight,
        width: this.window.innerWidth,
      },
    }

    this.sendMessageToChild(viewportUpdateBody, EVEN_VIEWPORT_UPDATE_EVENT)
  }
}
