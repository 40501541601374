function easeInOutQuad(t) {
  return t < 1 / 2
    ? 2 * t * t // Accelerate at t squared until halfway
    : -1 + 4 * t - 2 * t * t // Decelerate at 2 t squared at halfway
}

export function smoothScrollTo(element, pageY: number, duration: number): void {
  // Dont require requestAnimationFrame to be available
  const animate = window.requestAnimationFrame
    ? (callback) => window.requestAnimationFrame(callback)
    : (callback) => window.setTimeout(callback, 1000 / 60)
  const start = element.scrollTop
  const dY = pageY - start
  const animationStart = +new Date()

  function animateScroll() {
    const now = +new Date()
    const progress = easeInOutQuad((now - animationStart) / duration)
    const nudged = progress * dY + +start
    element.scrollTop = nudged

    if (now > animationStart + duration) {
      element.scrollTop = pageY
    } else {
      animate(animateScroll)
    }
  }
  animate(animateScroll)
}
