import { datadogRum } from '@datadog/browser-rum-slim'

export const captureNoChildElementWarning = () => {
  const error = new Error('Could not find child element')
  datadogRum.addError(error, {
    href: window.location.href,
  })
}

export const captureErrorOpeningWindowFromHelperEvent = (url: string) => {
  const error = new Error('Error opening window from helper script')
  datadogRum.addError(error, {
    url,
  })
}

export const captureCantSendHelperMessage = (iframeSrc: string) => {
  const error = new Error('Could not send Helper message')
  datadogRum.addError(error, {
    href: window.location.href,
    iframeSrc,
  })
}

export const captureOriginParseError = (err: Error, origin: string) => {
  datadogRum.addError(err, {
    href: window.location.href,
    origin,
  })
}
