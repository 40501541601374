export const EVEN_REQUEST_REDIRECT_EVENT = 'even_request_redirect'
export const EVEN_VIEWPORT_UPDATE_EVENT = 'even_viewport_update'

export const QueryParamDefaults = {}

export enum TargetTypes {
  CURRENT_WINDOW = '_self',
  NEW_TAB = '_blank',
}

export interface ViewportUpdateMessageBody {
  childRect: {
    bottom: number
    height: number
    left: number
    right: number
    top: number
    width: number
  }
  parentRect: {
    height: number
    width: number
  }
  automatedScrolling: boolean
}
